import React, { useContext } from 'react';
import '../../src/styles/Pagination.css';
import GlobalContext from "../context/GlobalContext";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { themeToggle } = useContext(GlobalContext);

  // Function to determine if the page button should be displayed
  const shouldShowPageButton = (page, currentPage, totalPages) => {
    // Always show the first, last, and adjacent pages
    return page === 1 || page === totalPages || page === currentPage || page === currentPage - 1 || page === currentPage + 1;
  };

  return (
    <div aria-label="Page navigation" className={`pagination-container dark:bg-[#232123] ${themeToggle ? 'bg-[#232123]' : 'theme-light'}`}>
      <ul className="pagination">
        <li className="page-item">
          <button
            onClick={() => onPageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className="page-button"
          >
            Previous
          </button>
        </li>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => {
          return shouldShowPageButton(page, currentPage, totalPages) ? (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <button
                onClick={() => onPageChange(page)}
                aria-current={currentPage === page ? 'page' : undefined}
                className={`page-button ${currentPage === page ? 'active' : ''}`}
                disabled={currentPage === page}  
              >
                {page}
              </button>
            </li>
          ) : (
            page === currentPage + 2 || (page === currentPage - 2 && page !== 2) ? (
              <li key={page} className="page-item">
                <span className="pagination-ellipsis dark:text-white">&hellip;</span>
              </li>
            ) : null
          );
        })}
        <li className="page-item">
          <button
            onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            className="page-button"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
