import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles/ConversationView.css'
import Avatar from '@mui/material/Avatar'
import copyIcon from '../assets/copy.png'
import GlobalContext from '../context/GlobalContext'
import Input from '../components/Input'
import { MdOutlineCheck } from 'react-icons/md'
import { ai_img } from '../staticData/icons'
import ReactMarkdown from 'react-markdown'
import { AiOutlineClose } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import { summaryKeyWords } from './constants'
import CustomModal from './shared/CustomModal'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';

import { Worker } from '@react-pdf-viewer/core';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const ChatBot = (props) => {
  const messageEndRef = useRef(null)
  const [copySuccessArray, setCopySuccessArray] = useState([])
  const [stopGeneration, setStopGeneration] = useState(false)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedJudgement, setSelectedJudgement] = useState(null)
  const [numPages, setNumPages] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const { id: Id } = useParams()

  const {
    chatData,
    errorMessage,
    chatLoading,
    themeToggle,
    currentRecievingChat,
    chatLoadingId,
    isChatDataLoading,
  } = useContext(GlobalContext)

  const customComponents = {
    div: ({ node, ...props }) => <div className='text-center' {...props} />,
    h1: ({ node, ...props }) => (
      <h1 className='text-lg font-medium my-1 dark:text-white' {...props} />
    ),
    h2: ({ node, ...props }) => (
      <h2 className='text-base font-normal my-1  dark:text-white' {...props} />
    ),
    h3: ({ node, ...props }) => (
      <h3 className='text-base font-medium my-1 dark:text-white' {...props} />
    ),
    h4: ({ node, ...props }) => (
      <h4 className='text-base font-medium my-1 dark:text-white' {...props} />
    ),
    h5: ({ node, ...props }) => (
      <h5 className='text-base font-normal my-1 dark:text-white' {...props} />
    ),
    strong: ({ node, ...props }) => (
      <strong className='font-medium  dark:text-white' {...props} />
    ),
    p: ({ node, ...props }) => (
      <p
        className='md:text-[16px] xs:text-[16px] mb-0  dark:text-white'
        {...props}
      />
    ),
    li: ({ node, ...props }) => (
      <li className='list-disc ml-5  dark:text-white text-[16px]' {...props} />
    ),
    ul: ({ node, ...props }) => (
      <ul className='mb-0' {...props} />
    ),
  }
  const cleanText = (text) => {
    return text
      .replace(/\*\*[^\*]+\*\*/g, '')
      .trim();
  };

  useEffect(() => {
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'nearest',
    }

    const timer = setTimeout(() => {
      messageEndRef.current?.scrollIntoView(scrollIntoViewOptions)
    }, 50)
    return () => clearTimeout(timer)
  }, [chatData?.length, currentRecievingChat])
 
  const convertS3UrlToHttps = (s3Url) => {
    return s3Url.replace(/^s3:\/\/([^\/]+)\/(.*)$/, 'https://$1.s3.amazonaws.com/$2');
  };

  const handleViewPdf = (judgment) => {
    const httpsUrl = convertS3UrlToHttps(judgment.document_url);
    setPdfUrl(httpsUrl); // Convert the S3 URL to HTTPS and set it for the Viewer
    setOpenModal(true); // Open the modal to display the PDF Viewer
  };


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  function addSpacesToSentence(sentence) {
    return sentence.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  const removeHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  const handleCopyClick = (content, index) => {
    // Always copy the chatbot data content
    let copyContent = removeHtmlTags(content); // Clean the content
    
    // Include the summary if the last entry in summaryVisible is true
    if (summaryVisible[summaryVisible.length - 1]) {
      const judgementData = chatData[index + 1]?.role === 'judgement'
        ? chatData[index + 1]
        : chatData[index - 1]?.role === 'judgement'
          ? chatData[index - 1]
          : null;
  
      if (judgementData) {
        const judgements = JSON.parse(judgementData.content).judgements;
        const updatedSummary = judgements
          .filter((judgment) => judgment.summary.trim() !== '')
          .map((judgment) => removeSummaryKeywords(judgment.summary))
          .join('\n\n'); // Concatenate summaries if there are multiple
  
        copyContent += `\n\n${updatedSummary}`; // Add the updated summary to the copied content
      }
    }
  
    // Copy the prepared content to the clipboard
    if (copyContent) {
      navigator.clipboard
        .writeText(copyContent)
        .then(() => {
          const updatedCopySuccessArray = [...copySuccessArray];
          updatedCopySuccessArray[index] = true;
          setCopySuccessArray(updatedCopySuccessArray);
  
          setTimeout(() => {
            const resetCopySuccessArray = [...copySuccessArray];
            resetCopySuccessArray[index] = false;
            setCopySuccessArray(resetCopySuccessArray);
          }, 1000);
        })
        .catch((err) => {
          console.error('Error copying text: ', err);
        });
    }
  };
  

  const summaryVisible = []
  function removeSummaryKeywords(text) {
    let result = text.replace(/Summary:\s*/gi, '').trim();
    result = result.replace(/Summary/gi, '').trim();
    return result;
  }

  return (
    <>
      <div className='w-[100%] flex items-start  mt-4 justify-start flex-col z-40 '>
        <span className='font-roboto 2xl:text-[22px] text-[15px] font-normal text-[#4f4f4f] dark:text-white'>
          How I can help you?
        </span>
      </div>

      <div className={`conversation-box ${isChatDataLoading ? 'grid' : ''}`}>
        <div className='w-[100%] flex justify-start mt-[10px]'>
          <div className='w-[45px] h-[45px] flex items-center justify-center'>
            {ai_img}
          </div>
          <div className=' w-[100%] m-h-[60px] mt-[-3px] ml-[26px] md:ml-[30px] flex items-start gap-[20px]'>
            <p className='pt-[10px] text-[#333] text-[15px] md:text[18px] 2xl:text-[18px] font-[400] font-roboto dark:text-white'>
              Please provide a brief but detailed description of the legal issue
              for which you would like me to research judgments.
            </p>
          </div>
        </div>
        {isChatDataLoading ? (
          <div className='loader'>
            <div
              className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-black align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
              role='status'
            >
              <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                Loading...
              </span>
            </div>
          </div>
        ) : chatData && chatData.length > 0 ? (
            chatData?.map((data, chatIndex) => {
            console.log("data",data)
            let judgementData = null
            if (
              data.role === 'user' &&
              summaryKeyWords.some((word) =>
                data?.content?.toLowerCase().includes(word.toLowerCase())
              )
            ) {
              summaryVisible.push(true)

            } else if (data.role === 'user') {
              summaryVisible.push(false)
            }
            if (data.role === 'ai') {
              judgementData =
                chatData[chatIndex + 1]?.role === 'judgement'
                  ? chatData[chatIndex + 1]
                  : chatData[chatIndex - 1]?.role === 'judgement'
                    ? chatData[chatIndex - 1]
                    : null
            }
            return (
              <>
                <div key={chatIndex}>
                  {data.role === 'user' && (
                    <div className='sender-div  dark:text-white'>
                      <Avatar
                        src='/broken-image.jpg'
                        sx={{
                          borderRadius: 0,
                          width: '45px',
                          height: '45px',
                        }}
                      />
                      <div className='sender-message'>
                        {data?.content?.split('\n')?.map((paragraph, index) => (
                          <p
                            key={index}
                            className='mb-2 text-[#333] text-[15px] md:text-[18px] xl:text-[17px] font-[500] font-roboto text-justify '
                          >
                            {addSpacesToSentence(paragraph)}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}

                  {data.role === 'ai' && (
                    <>
                      <div className='receiver-div relative'>
                        <div className='img-box md:w-[45px] xs:w-[20px] md:h-[45px] xs:h-[20px]'>{ai_img}</div>
                        <div
                          key={chatIndex}
                          className='general_receiver-message flex flex-col items-start justify-start text-justify mt-[0px]'
                        >
                          {data?.content?.split('\n')?.map((paragraph, index) => {
                            let fetchedContent = paragraph
                            fetchedContent = fetchedContent.replace(
                              /^ ?content=("|')/,
                              ''
                            )
                            fetchedContent = fetchedContent.replace(/("|')$/, '')
                            fetchedContent = fetchedContent.replace(
                              /^content='/,
                              ''
                            )
                            fetchedContent = fetchedContent.replace(/\\n/g, '\n')
                            return (
                              <>
                                <ReactMarkdown
                                  components={customComponents}
                                  key={index}
                                >
                                  {addSpacesToSentence(fetchedContent)}
                                </ReactMarkdown>
                                {copySuccessArray[chatIndex] ? (
                                  <div className='absolute top-0 right-[-45px]'>
                                    <MdOutlineCheck
                                      style={{
                                        color: '#212121',
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className='absolute top-0 md:right-[-45px] xs:right-[-12px] contrast-50'>
                                    <img
                                      src={copyIcon}
                                      alt='copyIcon'
                                      className='w-[25px] h-[25px] mt-[5px]'
                                      onClick={
                                        () =>
                                          handleCopyClick(
                                            data?.content,
                                            chatIndex
                                          )
                                      }
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                )}
                              </>
                            )
                          })}
                          {judgementData &&
                            summaryVisible[summaryVisible.length - 1] ===
                            true && (
                              <p className='dark:text-white mt-2 '>
                                Key Judgements:
                              </p>
                            )}
                          <ol className='dark:text-white' style={{ listStyleType: 'decimal' }}>
                            {judgementData &&
                              summaryVisible[summaryVisible.length - 1] &&
                              JSON.parse(judgementData.content).judgements
                                .filter((judgment) => judgment.summary.trim() !== '')
                                .map((judgment, index) => {
                                  let updatedSummary = removeSummaryKeywords(judgment.summary);
                                  updatedSummary = updatedSummary.replace(/^\s*[-*_]{3,}\s*$/gm, '');
                                  return (
                                    <li key={index}>
                                      <span className='font-semibold dark:text-white flex'>
                                        {judgment.document_number}
                                      </span>
                                      <ReactMarkdown
                                        components={customComponents}
                                        key={index}
                                      >
                                        {updatedSummary}
                                      </ReactMarkdown>
                                    </li>
                                  );
                                })
                            }
                          </ol>
                          <div className='my-btn'>
                            {judgementData &&
                              JSON.parse(judgementData.content).judgements.some(
                                (judgment) => judgment.present
                              ) && (
                                <div>
                                  <div className='heading my-4 px-1 pr-2 w-fit bg-gray-100 border-l-4 border-l-[#21808D]'>
                                    <h4>Reference:</h4>
                                  </div>
                                </div>
                              )}
                            {judgementData &&
                              JSON.parse(judgementData.content).judgements.map(
                                (judgment, index) => (
                                  <button
                                    key={index}
                                    onClick={() => {
                                      const cleanedJudgment = {
                                        ...judgment,
                                        summary: cleanText(judgment.summary),
                                      }
                                      setSelectedJudgement(cleanedJudgment)
                                      handleViewPdf(cleanedJudgment);
                                      setOpenModal(true) // Open modal
                                    }}
                                    className='py-2 px-2 mb-4 shadow-md no-underline rounded-lg bg-[#21808D] text-white text-justify font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2'
                                  >
                                    <span className='mt-2 font-semibold'>
                                      {' '}
                                      {judgment.document_number}
                                    </span>
                                  </button>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          })
        ) : (
          <div className="empty-chat">
            {/* <h1 className="text-black">New chat is here, message me!</h1> */}
          </div>
        )
        }

        {props.debugMessage && (
          <div className='receiver-div'>
            <div className='img-box md:w-[45px] xs:w-[20px] md:h-[45px] xs:h-[20px]'>{ai_img}</div>
            <div className='general_receiver-message flex flex-col items-start justify-start text-justify '>
              {props.debugMessage?.split('\n')?.map((paragraph, index) => (
                <ReactMarkdown
                  className='text-[15px] md:text-[16px] dark:text-black' key={index}
                  components={customComponents}
                  children={addSpacesToSentence(paragraph).replace(
                    /<b>(.*?)<\/b>/g,
                    (_, p1) => `**${p1}**`
                  )}
                />
              ))}
            </div>
          </div>
        )}

        {currentRecievingChat &&
          (Id === 'ChatBot' || parseInt(Id) === chatLoadingId) && (
            <>
              <div className='receiver-div relative'>
                <div className='img-box'>{ai_img}</div>
                <div className='general_receiver-message of  flex flex-col items-start justify-start text-justify mt-[0px]'>
                  {currentRecievingChat
                    ?.split('\n')
                    ?.map((paragraph, index) => (
                      <>
                        <ReactMarkdown
                          className='text-[15px] md:text-[16px] dark:text-black' key={index}
                          components={customComponents}
                          children={addSpacesToSentence(paragraph).replace(
                            /<b>(.*?)<\/b>/g,
                            (_, p1) => `**${p1}**`
                          )}
                        />
                      </>
                    ))}
                </div>
              </div>
            </>
          )}
        {chatLoading &&
          (Id === 'ChatBot' || parseInt(Id) === chatLoadingId) && (
            <div className='receiver-div d-flex align-items-center'>
              <div className='img-box'>{ai_img}</div>
              <div className='custom-loader'></div>
            </div>
          )}
        {errorMessage && (
          <div className='receiver-div d-flex align-items-center'>
            <div className='img-box'>{ai_img}</div>
            <div className='error-box'>{errorMessage}</div>
          </div>
        )}
        <div ref={messageEndRef} />
      </div>

      <div className='input-box'>
        <Input
          debugMessage={props.debugMessage}
          setDebugMessage={props.setDebugMessage}
          cancelTokenSource={cancelTokenSource}
          setCancelTokenSource={setCancelTokenSource}
          stopGeneration={stopGeneration}
          setStopGeneration={setStopGeneration}
        />
        <p className={`mb-0 ${themeToggle ? 'text-white' : 'text-gray-500'}`}>
          The information provided by this chatbot is for general informational
          purposes only and does not constitute legal advice.
        </p>
      </div>
      {openModal && (
        <div
          onClick={() => setOpenModal(false)}
          className='fixed top-0 bottom-0 left-0 right-0 flex items-start justify-center bg-[#767676] bg-opacity-20 backdrop-blur-sm z-[9999] py-10'
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className=' fixed flex flex-col w-[70%] h-[90vh]  bg-[#FCFCF9] overflow-auto custom-scrollbar'
          >
            <div
              className='cursor-pointer w-full flex items-end justify-end pt-2 px-2'
              onClick={() => setOpenModal(false)}
            >
              <AiOutlineClose size={20} className='text-[#13343B]' />
            </div>

            <div className='flex flex-col w-full h-full'>

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                />
              </Worker>
            </div>
          </div>
        </div>
      )}

    </>
  )
}

export default ChatBot
