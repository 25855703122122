
import React, { useContext, useEffect } from "react";
import "../styles/general.css";
import send_icon from "../assets/updateSendIcon.png";
import disable_send_icon from "../assets/send-icon-1.png";
import { ThreeDots } from "react-loader-spinner";
import GlobalContext from "../context/GlobalContext";
import { Refresh, Stop } from "../staticData/icons";
import axios from "axios";
import Cookies from "js-cookie";
import { parseJSON } from "date-fns";
import { SocketContext } from "../context/SocketContext"; // Update the path accordingly

function JudgementInput(props) {
  const {
    setJudgementChatData,
    setJudgementChatLoading,
    setJudgementErrorMessage,
    setJudgementInputQuery,
    judgementInputQuery,
    setJudgementSendBtnLoading,
    judgementSendBtnLoading,
    selectedTab,
    setQuerySubmission,
    clearJudgementChatData,
    userQuerySubmitted
  } = useContext(GlobalContext);
  const { sendJudgmentSocketCommand } = useContext(SocketContext);

  const API_BASE_URL = process.env.REACT_APP_BASE_URL_JUDGMENT_SOCKET;
  const handleChange = (e) => {
    setJudgementInputQuery(e.target.value);
  };
  const handleApiSubmit = (e) => {
    e.preventDefault();
    clearJudgementChatData();  // This function will dispatch the CLEAR_CHAT_DATA action
    setJudgementErrorMessage("");
    setJudgementSendBtnLoading(true);
    setJudgementChatLoading(true);
    const trimmedQuery = judgementInputQuery.trim();
    if (trimmedQuery !== "") {
      const messageWithLineBreaks = trimmedQuery.replace(/\n/g, "\n");
      setJudgementChatData([{ role: "user", content: messageWithLineBreaks }]);
      props.setStoredQuery(messageWithLineBreaks);
      Cookies.set("query", messageWithLineBreaks);
      setJudgementInputQuery("");
      setQuerySubmission(true)


      if (props.cancelTokenSource) {
        props.cancelTokenSource.cancel("Request canceled by user");
      }
      const newCancelTokenSource = axios.CancelToken.source();
      props.setCancelTokenSource(newCancelTokenSource);
      sendJudgmentSocketCommand({ query: judgementInputQuery });
    } else {
      setJudgementSendBtnLoading(false);
      setJudgementChatLoading(false);
    }
  };

  //   useEffect(() => {
  //     if (judgementInputQuery && userQuerySubmitted) {
  //         handleApiSubmit();  // Call the submit function when the query is set and the user has submitted it
  //     }
  // }, [judgementInputQuery, userQuerySubmitted]);


  const autoResize = (e) => {
    e.target.style.height = "24px";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleStopGenerating = () => {
    if (props.cancelTokenSource) {
      props.cancelTokenSource.cancel("Request canceled by user");
      props.setCancelTokenSource(null);
    }
    setJudgementSendBtnLoading(false);
    setJudgementChatLoading(false);
    props.setStopGeneration(true);
  };

  const handleGenerateText = (e) => {
    props.setStopGeneration(false);
    setJudgementErrorMessage("");
    setJudgementSendBtnLoading(true);
    setJudgementChatLoading(true);
    props.setStopGeneration(false);
    setJudgementInputQuery(props.storedQuery);
    handleApiSubmit();
  };
  //   e.preventDefault();
  //   setJudgementErrorMessage("");
  //   setJudgementSendBtnLoading(true);
  //   setJudgementChatLoading(true);
  //   const trimmedQuery = judgementInputQuery.trim();
  //   if (trimmedQuery !== "") {
  //     const messageWithLineBreaks = trimmedQuery.replace(/\n/g, "\n");
  //     setJudgementChatData([{ role: "user", content: messageWithLineBreaks }]);
  //     props.setStoredQuery(messageWithLineBreaks);
  //     Cookies.set("query", messageWithLineBreaks);
  //     setJudgementInputQuery("");

  //     if (props.cancelTokenSource) {
  //       props.cancelTokenSource.cancel("Request canceled by user");
  //     }
  //     const newCancelTokenSource = axios.CancelToken.source();
  //     props.setCancelTokenSource(newCancelTokenSource);
  //     axios
  //       .get(
  //         `${API_BASE_URL}/ai_bot/judgements/?query=${messageWithLineBreaks}`,
  //         { cancelToken: newCancelTokenSource.token }
  //       )
  //       .then((response) => {
  //         if (response.data.msg) {
  //           setJudgementChatData([
  //             { role: "message", content: response.data.msg },
  //           ]);
  //         } else {
  //           setJudgementChatData([{ role: "ai", content: response.data }]);
  //         }
  //         setJudgementSendBtnLoading(false);
  //         setJudgementChatLoading(false);
  //       })
  //       .catch((error) => {
  //         if (axios.isCancel(error)) {
  //         } else {
  //           setJudgementSendBtnLoading(false);
  //           setJudgementChatLoading(false);
  //           setJudgementErrorMessage(
  //             "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
  //           );
  //         }
  //       });
  //   } else {
  //     setJudgementSendBtnLoading(false);
  //     setJudgementChatLoading(false);
  //   }
  // };
  // const autoResize = (e) => {
  //   e.target.style.height = "24px";
  //   e.target.style.height = e.target.scrollHeight + "px";
  // };

  // const handleStopGenerating = () => {
  //   if (props.cancelTokenSource) {
  //     props.cancelTokenSource.cancel("Request canceled by user");
  //     props.setCancelTokenSource(null);
  //   }
  //   setJudgementSendBtnLoading(false);
  //   setJudgementChatLoading(false);
  //   props.setStopGeneration(true);
  // };
  // const handleGenerateText = (e) => {
  //   props.setStopGeneration(false);
  //   setJudgementErrorMessage("");
  //   setJudgementSendBtnLoading(true);
  //   setJudgementChatLoading(true);
  //   props.setStopGeneration(false);
  //   setJudgementInputQuery(props.storedQuery);
  //   handleApiSubmit();
  // };
  return (
    <>
      <div
        className="pb-[15px] flex items-center justify-end gap-2"
        style={{
          width: selectedTab === "Judgement" ? "95%" : "100%",
        }}
      >
      </div>

      <form
        id="input-container"
        className="dark:bg-white"
        style={{
          width: selectedTab === "Judgement" ? "95%" : "100%",
          marginTop:'20px',
        }}
      >
        <textarea
          type="text"
          placeholder="Hello, how can I help you..."
          onInput={autoResize}
          value={judgementInputQuery}
          onChange={(e) => handleChange(e)}
          onKeyUp={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.stopPropagation();
              if (judgementInputQuery && !judgementSendBtnLoading) {
                handleApiSubmit(e);
              }
            }
          }}
          style={{
            height: `${!judgementInputQuery && "50px"}`,
           
            outline: 'none',
            borderColor: 'transparent',
            boxShadow: 'none'
          }}
        />

        {judgementSendBtnLoading ? (
          <ThreeDots
            height="30"
            width="30"
            radius="9"
            color="gray"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <button type="submit">
            <>
              {judgementInputQuery ? (
                <img
                  src={send_icon}
                  alt=""
                  onClick={
                    judgementInputQuery && !judgementSendBtnLoading
                      ? handleApiSubmit
                      : (e) => e.preventDefault()
                  }
                />
              ) : (
                <>
                  <img
                    src={disable_send_icon}
                    alt=""
                    onClick={(e) => e.preventDefault()}
                  />
                </>
              )}
            </>
          </button>
        )}
      </form>
    </>
  );
}

export default JudgementInput;
